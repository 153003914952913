<template>
	<div class="page mine_page">
		<div class="block30"></div>
		<div class="w1200 flexdisplay flexbetween" style=" align-items: flex-start;" v-if="isLogin">
			<div class="leftbox">
				<div class="userbox">
					<div class="avatar"><img class="img100" :src="formObj.hlImage"/></div>
					<div class="textname clamp fontsize20">{{formObj.nikename}}</div>
				</div>
				<div class="typebox fontsize12">
					<div class="typename">账户管理</div>
					<div class="itemobj" :class="{'active':typeindex==0}" @click="handleType(0)">
						<div class="line"></div>
						<div class="icon">
							<img v-if="typeindex==0" class="img100" src="../assets/static/Frame0806.png"/>
							<img v-else class="img100" src="../assets/static/Frame0801.png"/>
						</div>
						<div class="text">个人信息</div>
						<div class="rightgo">
							<img v-if="typeindex==0" class="img100" src="../assets/static/Group61.png"/>
							<img v-else class="img100" src="../assets/static/Group6.png"/>
						</div>
					</div>
					<div class="itemobj" :class="{'active':typeindex==1}" @click="handleType(1)">
						<div class="line"></div>
						<div class="icon">
							<img v-if="typeindex==1" class="img100" src="../assets/static/Frame0802.png"/>
							<img v-else class="img100" src="../assets/static/Frame0803.png"/>
						</div>
						<div class="text">我的社团</div>
						<div class="rightgo">
							<img v-if="typeindex==1" class="img100" src="../assets/static/Group61.png"/>
							<img v-else class="img100" src="../assets/static/Group6.png"/>
						</div>
					</div>
					<div class="itemobj" :class="{'active':typeindex==2}" @click="handleType(2)">
						<div class="line"></div>
						<div class="icon">
							<img v-if="typeindex==2" class="img100" src="../assets/static/Frame0805.png"/>
							<img v-else class="img100" src="../assets/static/Frame0804.png"/>
						</div>
						<div class="text">课程订单</div>
						<div class="rightgo">
							<img v-if="typeindex==2" class="img100" src="../assets/static/Group61.png"/>
							<img v-else class="img100" src="../assets/static/Group6.png"/>
						</div>
					</div>
				</div>
			</div>
			<div class="rightbox" style=" align-items: flex-start;" v-if="!isLogin">
				<div class="nolist">请先登录</div>
			</div>
			<div class="rightbox" v-if="isLogin&&typeindex==0">
				<div class="formbox">
					<el-form :model="formObj">
						<el-form-item label="用户名">
							<el-input v-model="formObj.name" placeholder="请输入数字或者中文" disabled></el-input>
						</el-form-item>
						<el-form-item label="昵称">
							<el-input v-model="formObj.nikename" placeholder="请输入"></el-input>
						</el-form-item>
						<el-form-item>
							<div slot="label">头像</div>
							<div style="width: 100%;">
								<el-upload class="avatar-uploader" action="" :http-request="handleimageUrl" :show-file-list="false" :before-upload="beforeAvatarUpload">
									<img v-if="formObj.hlImage" :src="formObj.hlImage" class="el-avatar">
									<i v-else class="el-icon-plus avatar-uploader-icon" />
									<!-- <div slot="tg/png文件，且不超过5M</div> -->
								</el-upload>
							</div>
						</el-form-item>
						<el-form-item label="年级">
							<el-select v-model="formObj.grade">
								<el-option v-for="(item, index) in grades" :key="index" :label="item" :value="item" />
							</el-select>
						</el-form-item>
						<el-form-item label="年龄">
							<el-input v-model="formObj.age" placeholder="请输入" type="number"></el-input>
						</el-form-item>
						<el-form-item label="手机号码">
							<el-input type="number" v-model="formObj.phone" placeholder="请输入手机号码" disabled></el-input>
						</el-form-item>
						<el-form-item style="text-align: center; margin-top: 20px">
							<el-button type="danger" @click="dialogPassVisible=true">修改密码</el-button>
							<el-button type="primary" @click="hlUseredit('formObj')">点击保存更新</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
			<div class="rightbox1" v-if="isLogin&&typeindex==1">
				
			</div>
			<div class="rightbox2" v-if="isLogin&&typeindex==2">
				<div class="btntype">
					<div style="margin-right: 16px;" @click="handleordertype(0)">
						<el-button v-if="orderindex!=0">全部</el-button>
						<el-button v-if="orderindex==0" type="primary" plain>全部{{total}}</el-button>
					</div>
					<div style="margin-right: 16px;" @click="handleordertype(1)">
						<el-button v-if="orderindex!=1">已支付</el-button>
						<el-button v-if="orderindex==1" type="primary" plain>已支付{{total}}</el-button>
					</div>
					<div style="margin-right: 16px;" @click="handleordertype(2)">
						<el-button v-if="orderindex!=2">已兑换</el-button>
						<el-button v-if="orderindex==2" type="primary" plain>已兑换{{total}}</el-button>
					</div>
					<div @click="handleordertype(3)">
						<el-button v-if="orderindex!=3">已失效</el-button>
						<el-button v-if="orderindex==3" type="primary" plain>已失效{{total}}</el-button>
					</div>
					<div style="margin-left: auto;">
						<el-button @click="handleduihuan" type="primary" icon="el-icon-share">兑换课程</el-button>
					</div>
				</div>
				<div class="orderdatabox">
					<div class="itemobj" v-for="(item,index) in orderList" :key="index" v-if="item.trainSchedule">
						<div class="boxtop flexrow fontsize14">
							<div class="icon"><img class="img100" src="../assets/static/Frame08.png"></div>
							<div class="text1">订单编号：{{item.orderNum}}</div>
							<div class="text2">{{item.createDate}}</div>
						</div>
						<div class="conbox flexrow">
							<div class="icon"><img class="img100" :src="item.trainSchedule.imagesUrl"></div>
							<div class="text1box">
								<div class="text1 fontsize20 clamp">{{item.trainSchedule.title}}</div>
								<div class="text2 fontsize14 clamp2">{{item.trainSchedule.introduction}}</div>
								<div class="price fontsize16">￥{{item.trainSchedule.schedulePrice}}</div>
							</div>
							<div class="danprice fontsize16">￥{{item.allPrice}}</div>
							<div class="caozuobox">
								<div class="btn1 fontsize14" v-if="orderindex==1">支付成功</div>
								<div class="btn1 fontsize14" v-if="orderindex==2">兑换成功</div>
								<div class="tuikuan fontsize16" v-if="orderindex==1" @click="handletuikuan(index)">申请退款</div>
							</div>
						</div>
					</div>
					<div class="nolist" v-if="orderList.length<=0">暂无订单记录！</div>
					<div class="paginationbox">
					    <el-pagination
					      @current-change="handleCurrentChange"
					      :current-page.sync="orderForm.currentPage"
					      :page-size="orderForm.pageSize"
					      layout="prev, pager, next, jumper"
					      :total="total">
					    </el-pagination>
					</div>
				</div>
			</div>
		</div>
		<div class="block30"></div>
		<!--修改密码-->
		<el-dialog title="修改密码" :visible.sync="dialogPassVisible" append-to-body>
			<el-form ref="passForm" :model="passForm" :rules="passrules" :label-width="formLabelWidth">
				<el-form-item label="新密码" prop="password">
					<el-input v-model="passForm.password" placeholder="请输入登陆密码,只允许英文和数字" />
				</el-form-item>
				<el-form-item label="确认密码" prop="confirm">
					<el-input v-model="passForm.confirm" placeholder="请输入确认密码" />
				</el-form-item>
				<el-form-item style="text-align: center; margin-top: 20px">
					<el-button type="primary" @click="onSubmitPass('passForm')">确认修改</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import {mapState,mapMutations} from 'vuex'
import {
		showLoading,
		hideLoading
	} from '@/common/loading.js';
export default {
	data() {
		return {
			formLabelWidth:"128px",
			grades:['一年级','二年级','三年级','四年级','五年级','六年级','初一','初二','初三','高一','高二','高三','大学'],
			typeindex:0,//下标
			formObj: {},
			orderindex:0,//下标
			orderList:[],//订单
			passForm: {
				confirm: "",
				original: "",
				password: ""
			},
			dialogPassVisible: false,
			passrules: {
				original: [{
					required: true,
					message: '请输入原来密码',
					trigger: 'change'
				}],
				confirm: [{
					required: true,
					message: '请输入确认登录密码',
					trigger: 'change'
				}],
				password: [{
					required: true,
					message: '请输入登录密码',
					trigger: 'change'
				}],
			},
			orderForm:{
				currentPage:1,
				pageSize:5,
				orderState:[],
			},
			total: 0,
		};
	},
	mounted: function() {
		// console.log(this.userInfo)
		this.formObj = this.userInfo
		if(this.$route.params&&this.$route.params.type){
			if(this.$route.params.type=="order"){
				this.handleType(2)
			}
		}
		//获取订单
		this.PageGenOrders()
	},
	computed: {
		...mapState(['userInfo', 'isLogin','sysRole']),
	},
	methods: {
		...mapMutations(["SET_USERINFO",'SET_ISLOGIN']),
		//订单下标
		handleordertype(type){
			this.orderindex = type
			this.orderList = []
			this.handleCurrentChange(1)
		},
		//退款
		handletuikuan(index){
			this.$alert('您好，请在首页添加客服进行退款！', '提示', {
			    confirmButtonText: '确定',
			    callback: action => {}
			});
		},
		//点击页数
		handleCurrentChange(currentPage) {
			this.orderForm.currentPage = currentPage;
			this.PageGenOrders();
		},
		//获取订单
		PageGenOrders(){
			var _this = this
			var params = this.orderForm
			params["userUuid"] = this.userInfo.uuid
			if(this.orderindex==0){
				params["orderState"] = []
			}
			if(this.orderindex==1){
				params["orderState"] = [2]
			}
			if(this.orderindex==2){
				params["orderState"] = [3]
			}
			if(this.orderindex==3){
				params["orderState"] = [0,1]
			}
			showLoading()
			this.$http.post('GENOrdersModule/gen-orders/PageGenOrders', params).then(function(res) {
				hideLoading()
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					//获取类型json
					// records.forEach((item, index) => {
					// 	try{
					// 		item["infoTags"] = JSON.parse(item.infoTags)
					// 	}catch(e){
					// 		item["infoTags"] = []
					// 	}
					// });
					_this.total = res.data.total
					_this.orderList = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//修改密码成功
		onSubmitPass(form) {
			var _this = this
			this.$refs[form].validate(valid => {
				if (valid) {
					var params = _this.passForm;
					params["ids"] = [_this.formObj.id]
					if (_this.passForm.password != this.passForm.confirm) {
						_this.$message.error('密码和确认密码不一致');
						return false;
					}
					showLoading()
					_this.$http.post("app/hlexam/hlUser/pwd", params).then(function(res) {
						hideLoading()
						//数据处理
						if (res.code == 200) {
							_this.$message.success('修改密码成功');
							_this.dialogPassVisible = false;
							_this.$alert('密码修改成功，请重新登录账号！', '登录提示', {
							    confirmButtonText: '好的',
							    callback: action => {
									localStorage.setItem('token','');
									_this.SET_ISLOGIN(false)
									_this.SET_USERINFO({})
									//保存
									localStorage.setItem('key_state','')
							        _this.$router.push({ path: '/' }) 
							    }
							});
						} else {
							_this.$confirm(res.message, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {}).catch(() => {});
						}
					})
				} else {
					_this.$message.error('请填写必填数据');
					return false;
				}
			});
		},
		//保存更新用户
		hlUseredit(){
			var _this = this
			var params = this.formObj
			if(!params.nikename){
				this.$message.error("请输入昵称")
				return false;
			}
			if(!params.age){
				this.$message.error("请先输入年龄")
				return false;
			}
			params["token"] = null
			params["password"] = null
			showLoading()
			this.$http.post("app/hlexam/hlUser/edit", params).then(function(res) {
				hideLoading()
				//数据处理
				if (res.code == 200) {
					_this.$message.success("更新成功")
					_this.SET_USERINFO(params)
					//保存
					var obj = {
						userInfo: params,
						isLogin: true,//是否登录
					}
					localStorage.setItem('key_state',JSON.stringify(obj))
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//导航栏下标
		handleType(type){
			if(type==1){
				//我的社团
				this.$router.push({
					name: "mygroup"
				});
				return false;
			}
			this.typeindex = type
		},
		//兑换课程
		handleduihuan(){
			var _this = this
			this.$prompt('请输入兑换码', '兑换课程', {
			        confirmButtonText: '确定兑换',
			        cancelButtonText: '取消',
			        inputPlaceholder:"请输入"
			    }).then(({ value }) => {
					if(value){
						var params = {
							code: value,
							userUuid: _this.userInfo.uuid
						}
						_this.$http.post('TrainModule/train-user-schedule/code/getSchedule', params).then(function(res) {
							//数据处理
							if (res.code == 200) {
								_this.$alert('兑换课程成功！', '提示', {
								    confirmButtonText: '确定',
								    callback: action => {
								        
								    }
								});
							} else {
								_this.$confirm(res.message, '提示', {
									confirmButtonText: '确定',
									cancelButtonText: '取消',
									type: 'warning'
								}).then(() => {}).catch(() => {});
							}
						})
					}else{
						_this.$message({
							type: 'error',
							message: '请输入兑换码 '
						});
					}
			    }).catch(() => {
			        this.$message({
			            type: 'info',
			            message: '取消输入'
			        });       
			});
		},
		async handleimageUrl({
			file
		}) {
			var res = await this.$api.uploadHttp(file, {})
			if (res && res.status == 200) {
				this.formObj.hlImage = res.requestUrls[0]
				this.$message.success('上传图片成功!')
			} else {
				this.$message.error('上传图失败!')
			}
		},
		beforeAvatarUpload(file) {
			// console.log(file)
			const isLt10M = file.size / 1024 / 1024 < 5
			if (!isLt10M) {
				this.$message.error('上传头像图片大小不能超过 5MB!')
				return false
			}
			if (file.type == 'image/jpeg' || file.type == 'image/png') {
				console.log(this.BASEURL)
			} else {
				this.$message.error('上传头像图片只能是 JPG/PNG 格式!')
			}
		}
		
	}
};
</script>
<style lang="scss" scoped></style>
